import React from 'react';
import './main.css';

const colors = ['red', 'blue', 'green', 'yellow'];
const minClumpSize = 3; // The magic number!
const gameSizes = [15, 20, 25, 30];

function getPositionElement (num) {
  if (num === 1) {
    return (
      <div className="game-over__text">
        You got the <b>top score</b>!
      </div>
    )
  }
  else if (num === 2) num = '2nd';
  else if (num === 3) num = '3rd';
  else num += 'th';
  return (
    <div className="game-over__text">
      You got the <b>{num}</b> highest score!
    </div>
  )
}

function padZero (str) {
  if ((str + '').length === 2) return str;
  else return '0' + str;
}

function formatTs (ts) {
  const date = new Date(ts);
  let str = '';
  str += padZero(date.getDate()) + '/';
  str += padZero(date.getMonth() + 1) + '/';
  str += (date.getFullYear() % 2000) + ' ';
  str += padZero(date.getHours()) + ':';
  str += padZero(date.getMinutes());
  return str;
}

// Components.

class Ball extends React.Component {
  constructor (props) {
    super(props);
    this.state = { lastAction: null };
  }
  onTouch () {
    // Flag as touch screen so we know to ignore dummy focus events.
    this.setState({ isTouch: true });
  }
  onInteract (clicked, focused) {
    // Ignore non-click actions for touch screens.
    if (this.state.isTouch && !clicked) return;
    // Work out what action type just occured.
    let action;
    if (clicked) action = this.props.focused ? 'click' : 'focus';
    else action = focused ? 'focus' : 'unfocus';
    // Ignore duplicate actions as it will just cause a double render.
    if (action === this.state.lastAction) return;
    this.setState({
      isTouch: false,
      lastAction: action
    });
    // Report action upwards.
    this.props.doClumpAction(action, this.props.col, this.props.row);
  }
  render () {
    const classes = [
      'ball',
      'ball--' + this.props.color,
      this.props.focused ? 'ball--highlight' : ''
    ].join(' ');
    return (
      <div
        onTouchStart={this.onTouch.bind(this)}
        onMouseEnter={this.onInteract.bind(this, false, true)}
        onMouseLeave={this.onInteract.bind(this, false, false)}
        onClick={this.onInteract.bind(this, true, false)}
      >
        <div className={classes} />
      </div>
    )
  }
}

class Column extends React.Component {
  render () {
    return (
      <div className="column">
        {
          this.props.rows.map((row) => {
            return (
              <Ball
                key={row.id}
                color={row.color}
                focused={row.focused}
                col={row.col}
                row={row.row}
                doClumpAction={this.props.doClumpAction}
              />
             )
          })  
        }
      </div>
    );
  }
}

class Button extends React.Component {
  render () {
    return (
      <div className="button" onClick={this.props.clickFn}>
        <div className="button__icon"/>
        <span className="button__text">{this.props.text}</span>
      </div>
    )
  }
}

class Score extends React.Component {
  constructor (props) {
    super(props);
    this.addAnimTimeout = null;
    this.state = { lastClickId: 0 };
  }
  resetAnimTimeout () {
    clearTimeout(this.addAnimTimeout);
    const newClickId = this.props.clickId;
    this.addAnimTimeout = setTimeout(() => {
      this.setState({ lastClickId: newClickId });
    }, 0);
  }
  render () {
    const newClick = this.props.clickId !== this.state.lastClickId;
    if (newClick) this.resetAnimTimeout();
    // const zeros = '000'.substr(0, 4 - (this.props.score + '').length);
    const preScoreClasses = ['pre-score', 'flex-grow'];
    if (this.props.focusScore === 0) {
      preScoreClasses.push('invisible')
    }
    return (
      <>
        <div className={preScoreClasses.join(' ')}>
          <span className="pre-score__text">+{this.props.focusScore}</span>
        </div>
        <div className="score">
          {/* <span className="score__prefix">{zeros}</span> */}
          <span className={`score__number ${newClick ? '' : 'score__number--anim'}`}>
            {this.props.score}
          </span>
          <span className="score__label">pts</span>
        </div>
      </>
    );
  }
}

class Game extends React.Component {
  constructor (props) {
    super(props);
    // Set up state.
    this.nextId = 0;
    this.gameData = null;
    this.boardDate = null;
    this.state = {
      boardData: null,
      score: 0,
      focusCount: 0,
      focusScore: 0,
      clickId: 0,
      clumpsRemain: true,
      highScores: this.getHighScores(),
      size: gameSizes[0]
    };
    // Bind start function.
    this.startNewGame = this.startNewGame.bind(this);
    this.doClumpAction = this.doClumpAction.bind(this);
  }
  changeGameSize(size) {
    // Change the game size, and start a new game if the score is zero.
    this.setState({ size });
    if (!this.state.score) setTimeout(this.startNewGame.bind(this), 0);
  }
  getHighScores (newScore) {
    // Get existing list of high scores from state or storage.
    let scores = {};
    if (this.state && this.state.highScores) {
      scores = this.state.highScores;
    } else if (typeof Storage !== 'undefined') {
      // Uncomment next line to clear high scores!
      // window.localStorage.removeItem('high-scores');
      const fromStorage = window.localStorage.getItem('high-scores');
      if (fromStorage) scores = JSON.parse(fromStorage);
    }
    // Add new score if provided.
    if (newScore) {
      scores[this.state.size] = scores[this.state.size] || [];
      const score = {
        score: newScore,
        date: Date.now(),
        key: Date.now()
      };
      scores[this.state.size].push(score);
      scores[this.state.size].sort(function (a, b) {
        if (a.score > b.score) return -1;
        else if (a.score === b.score) return 0;
        else return 1;
      });
      // TODO: Do this better.
      this.lastScorePosition = scores[this.state.size].indexOf(score) + 1;
    }
    // Ensure we have at least 10, but no more than 20
    // high scores for each game size.
    gameSizes.forEach(function (size) {
      const set = scores[size] || [];
      while (set.length > 20) set.pop();
      while (set.length < 10) {
        set.push({
          score: 0,
          date: 0,
          key: set.length
        });
      }
      scores[size] = set;
    });
    // Save to storage and return.
    if (typeof Storage !== 'undefined') {
      window.localStorage.setItem('high-scores', JSON.stringify(scores));
    }
    return scores;
  }
  generateGameData (size) {
    var gameData = [];
    for (var col = 0; col < size; col++) {
      gameData[col] = {
        id: col.toString(), // e.g. "0"
      };
      var rows = [];
      for (var row = 0; row < size; row++) {
        rows.push({
          id: `${col},${row}`, // e.g. "0,0"
          color: colors[Math.floor(Math.random() * colors.length)],
          deleted: false,
          focused: false
        });
      }
      gameData[col].rows = rows;
    }
    this.gameData = gameData;
  }
  refreshBoardData () {
    // console.log('refreshBoardData');
    // Update the board data and save to state object.
    // Board data is a compacted version of the game data with
    // additional col/row data as it appears on the board.
    const usedCols = [];
    const unusedCols = [];
    for (let col = 0; col < this.gameData.length; col++) {
      const gameColumn = this.gameData[col];
      const boardColumn = {
        id: gameColumn.id,
        rows: []
      };
      for (let row = 0; row < gameColumn.rows.length; row++) {
        const gameRow = gameColumn.rows[row];
        if (!gameRow.deleted) {
          boardColumn.rows.push({
            id: gameRow.id,
            color: gameRow.color,
            focused: gameRow.focused,
            col: usedCols.length,
            row: boardColumn.rows.length
          });
        }
      }
      (boardColumn.rows.length ? usedCols : unusedCols)
      .push(boardColumn);
    }
    // Store board data.
    this.boardData = usedCols.concat(unusedCols);
    // Check if clumps still remain.
    const remained = this.state.clumpsRemain;
    const remain = this.doClumpsRemain();
    if (remained && !remain) {
      // Update high scores.
      this.getHighScores(this.score);
    }
    // Set data on state.
    this.setState({
      boardData: this.boardData,
      clumpsRemain: remain
    });
  }
  updateScore (add, set) {
    let score = this.state.score;
    if (typeof set === 'number') score = set;
    else score += add;
    // For some reason, setting the high score from
    // the one stored on state doesn't work and ignores
    // the last update, so we use this.score as the
    // definitive record.
    this.score = score;
    const toUpdate = {
      score: score,
      focusCount: 0,
      focusScore: 0
    };
    this.setState(toUpdate);
  }
  startNewGame () {
    // Generate new game data and refresh the board data.
    this.generateGameData(this.state.size);
    this.updateScore(null, 0);
    this.refreshBoardData();
  }
  componentDidMount () {
    this.startNewGame();
  }
  getTargetIfColor (col, row, color) {
    // Check that neither column or row are negative.
    if (col < 0 || row < 0) return null;
    // Get the target if it exists.
    const column = this.boardData[col];
    const target = column ? column.rows[row] : null;
    // Return the target if the colour matches.
    return target && target.color === color ? target : null;
  }
  expandClump (target, clump, max = 0) {
    // Skip if already have the maximum clump size required.
    if (max && Object.keys(clump).length >= max) return;
    // Skip if no target or already in the clump.
    if (!target || clump[target.id]) return;
    // Add target to clump.
    clump[target.id] = true;
    // Check each location adjacent to the target.
    const row = target.row;
    const col = target.col;
    const toCheck = [
      [col, row + 1], [col, row - 1], // North, South
      [col + 1, row], [col - 1, row]  // Left, Right
    ];
    toCheck.forEach((position) => {
      this.expandClump(
        this.getTargetIfColor(position[0], position[1], target.color),
        clump,
        max
      );
    });
  }
  doClumpsRemain () {
    if (!this.boardData) return;
    let found = false;
    for (let col = 0; col < this.state.size; col++) {
      if (found) break;
      const column = this.boardData[col];
      if (column) {
        for (let row = 0; row < this.state.size; row++) {
          if (found) break;
          const clump = {};
          this.expandClump(column.rows[row], clump, minClumpSize);
          if (Object.keys(clump).length >= minClumpSize) found = true;
        }
      }
    }
    return found;
  }
  getClump (col, row) {
    // Find all balls in a clump around the target at col/row.
    const target = this.boardData[col].rows[row];
    const clump = {};
    this.expandClump(target, clump);
    return clump;
  }
  doClumpAction (action, col, row) {
    // Do nothing if no clumps remain.
    if (!this.state.clumpsRemain) return;
    // Perform a clump action.
    const clumpIds = Object.keys(this.getClump(col, row));
    const stateUpdate = {
      focusCount: 0,
      focusScore: 0
    };
    // Clumps must have at least X members.
    if (clumpIds.length >= minClumpSize) {
      stateUpdate.focusCount = clumpIds.length;
      clumpIds.forEach((clumpId) => {
        // Get position in game data.
        const [gameCol, gameRow] = clumpId.split(',').map(Number);
        const target = this.gameData[gameCol].rows[gameRow];
        // Mark the target based on the action.
        target.focused = action === 'focus';
        if (action === 'click') target.deleted = true;
      });
      // Calculate the clump score.
      // +1 after every 3, e.g. 1,2,3,5,7,9,12,15,18,22,26,30,...
      if (action !== 'unfocus') {
        for (let i = 0; i < clumpIds.length; i++) {
          const jump = Math.floor(i / 3) + 1;
          stateUpdate.focusScore += jump;
        }
      }
      // If removing the clump, update the score and click id,
      // then reset the focus values.
      if (action === 'click') {
        this.updateScore(stateUpdate.focusScore);
        stateUpdate.clickId = this.state.clickId + 1;
        stateUpdate.focusCount = 0;
        stateUpdate.focusScore = 0;
      }
    }
    // Perform state update then refresh the board.
    this.setState(stateUpdate);
    this.refreshBoardData();    
  }
  render () {
    // console.log('gameRender');
    const scores = this.state.highScores[this.state.size].slice(0, 10).map(function (highScore, i) {
      const classes = 'high-score high-score--' + (i % 2 ? 'odd' : 'even');
      return (
        <div key={highScore.key} className={classes}>
          <span className="high-score__place">{i + 1}.</span>
          {(highScore.score && <span className="high-score__date">{formatTs(highScore.date)}</span>) || null}
          <span className="high-score__score bold">{highScore.score ? highScore.score : '-'}</span>
        </div>
      );
    });
    const sizes = gameSizes.map((size, i) => {
      let classes = 'game-size game-size--';
      if (size === this.state.size) classes += 'selected';
      else classes += (i % 2 ? 'odd' : 'even');
      return <div key={size} className={classes} onClick={this.changeGameSize.bind(this, size)}>{size}</div>
    });
    return (
      <div className="container">
        <div className="container__column">
          <div className="top-panel">
            <Score
              score={this.state.score}
              clickId={this.state.clickId}
              focusCount={this.state.focusCount}
              focusScore={this.state.focusScore}
            />
          </div>
          <div className="side-panel side-panel--high-scores">
            <div className="side-panel__header">High Scores</div>
            <div className="side-panel__container">
              {scores}
            </div>
          </div>
          <div className="side-panel side-panel--game-size">
            <div className="side-panel__header">Game Size</div>
            <div className="side-panel--game-size__container">
              {sizes}
            </div>
          </div>
          <div className="top-panel">
            <Button text="New game" clickFn={this.startNewGame} />
          </div>
        </div>
        <div className="container__column">
          <div className="game-area">
          {
            (this.state.boardData || []).map((col) => {
              return (
                <Column
                  key={col.id}
                  rows={col.rows}
                  doClumpAction={this.doClumpAction}
                />
              );
            })
          }
          {
            !this.state.clumpsRemain && (
              <div className="game-over">
                <div className="game-over__container">
                  { this.lastScorePosition > 10 && <div className="game-over__text">GAME OVER</div>}
                  { this.lastScorePosition <= 10 && (
                      <>
                        <div className="game-over__text bold">Congratulations!</div>
                        {getPositionElement(this.lastScorePosition)}
                      </>
                    )
                  }
                </div>
              </div>
            )
          }
          </div>
        </div>
      </div> 
    )
  }
}

export default Game;
